
<script setup>
import {ThemeProvider} from "vue3-styled-components";
import {theme} from "./config/theme/themeVariables";
import {computed, ref, watch} from "vue";
import {useStore} from "vuex";
import useAuth from "@/view/authentication/useAuth";
import {setRefreshToken} from "@/config/api/setRefreshToken";
import nlNL from "ant-design-vue/es/locale/nl_NL";
import {ConfigProvider} from "ant-design-vue";
import {useLocalAuth} from "@/pinia/AuthLocalStore.js";
import {storeToRefs} from "pinia";

const {isRemembered, hasSession} = useAuth();
const globalLoading = ref(false);

const {state} = useStore();
const rtl = computed(() => state.themeLayout.rtlData);
const isLoading = computed(() => state.themeLayout.loading && globalLoading.value);
const darkMode = computed(() => state.themeLayout.data);
const topMenu = computed(() => state.themeLayout.topMenu);
const localAuth = useLocalAuth();
const {selected_role} = storeToRefs(localAuth);
if (!hasSession.value && !isRemembered()) {
  console.log('load1');
  globalLoading.value = false;
}
console.log(hasSession, 'hasSes')
if (hasSession.value || isRemembered()) {
  console.log('load2');

  setRefreshToken().catch(e=>{
    console.log(e,'error');
  }).finally(() => {
    globalLoading.value = false
  })
}
ConfigProvider.config({
  theme: {
    primaryColor: "#226FB0",
    borderRadiusBase: "#226FB0",
  },
});

watch(()=>selected_role.value,(v)=>{
  console.log(v,'ROLEE')
})

</script>

<template>
  <a-config-provider :locale="nlNL">
    <div v-if="isLoading" class="spin">
      <a-spin/>
    </div>
    <ThemeProvider
        v-else
        :theme="{
      ...theme,
      rtl,
      topMenu,
      darkMode,
    }"
    >
      <Suspense>
        <template #default>
          <router-view></router-view>
        </template>
        <template #fallback>
          <div class="spin">
            <a-spin/>
          </div>
        </template>
      </Suspense>
    </ThemeProvider>
  </a-config-provider>
</template>
<style lang="scss">
.hoverable:hover{
  cursor:pointer;
  opacity:0.5;
  transition: 0.3s;
}
.ant-btn-primary{
  background: #226FB0;
  border-color:#226FB0;
}
</style>
