import app from "@/config/configApp.js";
import commonEN from '@/translations/en/common.json'
import dashboardEN from '@/translations/en/dashboard.json'
import usersEN from '@/translations/en/users.json'
import financialEN from '@/translations/en/financial.json'
import authEN from '@/translations/en/auth.json'
import tasksEN from '@/translations/en/tasks.json'
import commonNL from '@/translations/nl/common.json'
import dashboardNL from '@/translations/nl/dashboard.json'
import usersNL from '@/translations/nl/users.json'
import financialNL from '@/translations/nl/financial.json'
import authNL from '@/translations/nl/auth.json'
import tasksNL from '@/translations/nl/tasks.json'
import {createI18n} from "vue-i18n";

const messages = {
    en: {
        common: commonEN,
        dashboard: dashboardEN,
        tasks: tasksEN,
        financial: financialEN,
        auth: authEN,
        users: usersEN
    },
    nl: {
        common: commonNL,
        dashboard: dashboardNL,
        tasks: tasksNL,
        financial: financialNL,
        auth: authNL,
        users: usersNL
    },
}

export const i18n = createI18n({
    locale: "nl",
    messages: messages,
    numberFormats: {
        en: {
            currency: {
                style: "currency",
                currency: "EUR",
            },
        },
    },
});
app.use(i18n);